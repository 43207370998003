import mapKeys from 'lodash/mapKeys';

export interface currencyType {
  id: number;
  value: string;
  label: string;
}

export const currencyData: currencyType[] = [
  { id: 36, value: 'AUD', label: 'Австралийский доллар' },
  { id: 944, value: 'AZN', label: 'Азербайджанский манат' },
  { id: 51, value: 'AMD', label: 'Армянский драм' },
  { id: 933, value: 'BYN', label: 'Белорусский рубль' },
  { id: 975, value: 'BGN', label: 'Болгарский лев' },
  { id: 986, value: 'BRL', label: 'Бразильский реал' },
  { id: 348, value: 'HUF', label: 'Венгерский форинт' },
  { id: 410, value: 'KRW', label: 'Вон Республики Корея' },
  { id: 704, value: 'VND', label: 'Вьетнамский донг' },
  { id: 344, value: 'HKD', label: 'Гонконгский доллар' },
  { id: 981, value: 'GEL', label: 'Грузинский лари' },
  { id: 208, value: 'DKK', label: 'Датская крона' },
  { id: 784, value: 'AED', label: 'Дирхам ОАЭ' },
  { id: 840, value: 'USD', label: 'Доллар США' },
  { id: 978, value: 'EUR', label: 'Евро' },
  { id: 818, value: 'EGP', label: 'Египетский фунт' },
  { id: 356, value: 'INR', label: 'Индийская рупия' },
  { id: 360, value: 'IDR', label: 'Индонезийская рупия' },
  { id: 398, value: 'KZT', label: 'Казахстанский тенге' },
  { id: 124, value: 'CAD', label: 'Канадский доллар' },
  { id: 634, value: 'QAR', label: 'Катарский риал' },
  { id: 417, value: 'KGS', label: 'Киргизский сом' },
  { id: 156, value: 'CNY', label: 'Китайский юань' },
  { id: 498, value: 'MDL', label: 'Молдавский лей' },
  { id: 554, value: 'NZD', label: 'Новозеландский доллар' },
  { id: 934, value: 'TMT', label: 'Новый туркменский манат' },
  { id: 578, value: 'NOK', label: 'Норвежская крона' },
  { id: 985, value: 'PLN', label: 'Польский злотый' },
  { id: 946, value: 'RON', label: 'Румынский лей' },
  { id: 941, value: 'RSD', label: 'Сербский динар' },
  { id: 702, value: 'SGD', label: 'Сингапурский доллар' },
  { id: 972, value: 'TJS', label: 'Таджикский сомони' },
  { id: 764, value: 'THB', label: 'Таиландский бат' },
  { id: 949, value: 'TRY', label: 'Турецкая лира' },
  { id: 860, value: 'UZS', label: 'Узбекский сум' },
  { id: 980, value: 'UAH', label: 'Украинская гривна' },
  { id: 826, value: 'GBP', label: 'Фунт Стерлингов' },
  { id: 203, value: 'CZK', label: 'Чешская крона' },
  { id: 752, value: 'SEK', label: 'Шведская крона' },
  { id: 756, value: 'CHF', label: 'Швейцарский франк' },
  { id: 710, value: 'ZAR', label: 'Южноафриканский рэнд' },
  { id: 392, value: 'JPY', label: 'Японская иена' },
];

export const currencyMap = mapKeys(currencyData, 'value');
